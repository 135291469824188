import "./index.scss";
import { ReactComponentElement, useEffect, useState } from "react";

import { createPortal } from "react-dom";

import Close from "../../assets/svgs/icon-close.svg";

interface Props {
  title: string;
  onClose: () => void;
  children: ReactComponentElement<any>;
}

const Modal = ({ title, onClose, children }: Props) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [onClose]);

  const handleModalClose = () => {
    setIsAnimating(true);
    setTimeout(() => {
      onClose();
      setIsAnimating(false);
    }, 400);
  };

  return createPortal(
    // 모달을 렌더링할 위치를 지정 / 부모 컴포넌트에 영향을 받지 않음
    <div className={`modal-background ${isAnimating ? "close" : ""}`}>
      <div className={`modal-container ${isAnimating ? "close" : ""}`}>
        <div className={"modal-box-top"}>
          <div className={"modal-box-top-title"}> {title} </div>
          <img className={"modal-box-top-close"} src={Close} alt={""} onClick={handleModalClose} />
        </div>
        <div className={"modal-content"}>{children}</div>
      </div>
    </div>,
    document.body, // 모달을 body에 렌더링
  );
};

export default Modal;
