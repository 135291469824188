import "./index.scss";

import CircularProgress from "@mui/material/CircularProgress";

const ModalButtonLoading = () => {
  return (
    <div className={"modal-button-loading-container"}>
      <CircularProgress color={"inherit"} size={"16px"} />
    </div>
  );
};

export default ModalButtonLoading;
