import "./index.scss";

interface Props {
  message: string;
  onClick: any;
  disabled?: boolean;
}

const ModalButton = ({ message, onClick, disabled }: Props) => {
  return (
    <button
      className={`modal-button-container ${disabled && "disabled"}`}
      onClick={onClick}
      disabled={disabled}>
      {message}
    </button>
  );
};

export default ModalButton;
