const BASE_URL = process.env.REACT_APP_API_URL;

export const API_URL = {
  MARKET_LIST: `${BASE_URL}market`,
  NETWORK_FEE: `${BASE_URL}market/network_fee`,
  COLLATERAL_LIST: `${BASE_URL}collateral`,
  CURRENCY_LIST: `${BASE_URL}currency`,
  CREATE_REFERRAL_CODE: `${BASE_URL}referral/code`,
  REFERRAL: `${BASE_URL}referral`,
  PRICE: `${BASE_URL}market/price`,
  USER: `${BASE_URL}user`,
  MESSAGE: `${BASE_URL}message`,
  SIGNUP: `${BASE_URL}user/signup`,
  SIGNIN: `${BASE_URL}user/signin`,
  SIGNOUT: `${BASE_URL}user/signout`,
  DEPOSIT_TRANSACTION: `${BASE_URL}account/deposit/transaction`,
  DEPOSIT_QUERY: `${BASE_URL}account/deposit/query`,
  WITHDRAW_TRANSACTION: `${BASE_URL}account/withdraw/transaction`,
  WITHDRAW_QUERY: `${BASE_URL}account/withdraw/query`,
  SWAP_TRANSACTION: `${BASE_URL}account/swap/transaction`,
  SWAP_QUERY: `${BASE_URL}account/swap/query`,
  CHECKIN: `${BASE_URL}account/event/checkin`,
  CHECKIN_QUERY: `${BASE_URL}account/event/checkin/query`,
  DEPOSIT_HISTORY: `${BASE_URL}account/deposit/history`,
  WITHDRAWAL_HISTORY: `${BASE_URL}account/withdraw/history`,
  SWAP_HISTORY: `${BASE_URL}account/swap/history`,
  MARKET_ORDER_HISTORY: `${BASE_URL}order/market/history`,
  COLLATERAL_MANAGEMENT_HISTORY: `${BASE_URL}order/collateral/history`,
  LIMIT_ORDER_HISTORY: `${BASE_URL}order/limit/history`,
  TRIGGER_ORDER_HISTORY: `${BASE_URL}order/trigger/history`,
  ACMM_MARGIN_HISTORY: `${BASE_URL}order/acmm-history/history`,
  MARKET_OPEN_TRANSACTION: `${BASE_URL}order/market_open`,
  MARKET_OPEN_QUERY: `${BASE_URL}order/market_open/query`,
  CREATE_LIMIT_ORDER_TRANSACTION: `${BASE_URL}order/create_limit`,
  LIMIT_ORDER_QUERY: `${BASE_URL}order/create_limit/query`,
  ADD_COLLATERAL_TRANSACTION: `${BASE_URL}order/add_collateral`,
  DECREASE_COLLATERAL_TRANSACTION: `${BASE_URL}order/sub_collateral`,
  ADD_COLLATERAL_QUERY: `${BASE_URL}order/add_collateral/query`,
  DECREASE_COLLATERAL_QUERY: `${BASE_URL}order/sub_collateral/query`,
  CLOSE_MARKET_TRANSACTION: `${BASE_URL}order/market_close`,
  CLOSE_MARKET_QUERY: `${BASE_URL}order/market_close/query`,
  CANCEL_LIMIT_ORDER_TRANSACTION: `${BASE_URL}order/cancel_limit`,
  CANCEL_LIMIT_ORDER_QUERY: `${BASE_URL}order/cancel_limit/query`,
  GET_ACCUMULATED_VOLUME: `${BASE_URL}order/get_accumulated_volume`,
  GET_LEADER_BOARD: `${BASE_URL}order/get_leader_board`,
  CREATE_TRIGGER_ORDER: `${BASE_URL}order/trigger/create`,
  GET_TRIGGER_ORDER: `${BASE_URL}order/trigger/active`,
  CANCEL_TRIGGER_ORDER: `${BASE_URL}order/trigger/cancel`,
  LEADERBOARD_TODAY: `${BASE_URL}phase2/leaderboard/today`,
  LEADERBOARD_TOTAL: `${BASE_URL}phase2/leaderboard/total`,
  BONUS: `${BASE_URL}phase2/bonus`,
  ACCUMULATED_POINTS: `${BASE_URL}phase2/point/accumulated`,
  BOOST_TOMORROW: `${BASE_URL}phase2/boost/tomorrow`,
  POINT_TODAY: `${BASE_URL}phase2/point/today`,
  POINT_ALL: `${BASE_URL}phase2/point/all`,
  POINT_PHASE1: `${BASE_URL}phase2/phase1`,
  GET_ORDER_QUOTE: `${BASE_URL}order/get_order_quote`,
  GET_SWAP_QUOTE_INPUT: `${BASE_URL}order/get_swap_quote_input`,
  GET_SWAP_QUOTE_OUTPUT: `${BASE_URL}order/get_swap_quote_output`,
  GET_ESTIMATE_LIQUIDATION_PRICE: `${BASE_URL}order/get_estimate_liquidation_price`,
  GET_LIQUIDATION_PRICE: `${BASE_URL}order/get_liquidation_price`,
  GET_POSITION_NET_VALUE_TOKEN: `${BASE_URL}order/get_position_net_value_token`,
  GET_WALLET_BALANCE: `${BASE_URL}reader/get_wallet_balance`,
  GET_ACCOUNT_BALANCE: `${BASE_URL}reader/get_account_balance`,
  GET_ACCOUNT_LOCKED_BALANCE: `${BASE_URL}reader/get_account_locked_balance`,
  GET_ACCOUNT_WITHDRAWABLE_BALANCE: `${BASE_URL}reader/get_account_withdrawable_balance`,
  GET_ACCOUNT_FEE_DEBT: `${BASE_URL}reader/get_account_fee_debt`,
  GET_TOKEN_AMOUNT_IN_USE: `${BASE_URL}reader/get_token_amount_inuse`,
  GET_TOKEN_AMOUNT_IN_USE_COLLATERAL: `${BASE_URL}reader/get_token_amount_inuse_collateral`,
  GET_POSITIONS: `${BASE_URL}reader/get_positions`,
  GET_LIMIT_ORDERS: `${BASE_URL}reader/get_limit_orders`,
  GET_TOKEN_NONCE: `${BASE_URL}reader/get_token_nonce`,
};
