import { styled } from "@mui/material";
import { MaterialDesignContent, SnackbarProvider, useSnackbar } from "notistack";
import { WagmiConfig } from "wagmi";
import "./App.css";

import { CloseButton } from "./components/common/Snackbar/CloseButton";
import {
  SnackbarDesignContentError,
  SnackbarDesignContentSuccess,
} from "./components/common/Snackbar/SnackbarDesignContents";
import { GlobalInit } from "./redux/components/GlobalInit";
import Router from "./routes/Router";
import { wagmiConfig } from "./wagmiConfig";

function App() {
  function detectBrowser(): string {
    const userAgent = navigator.userAgent;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      return "Chrome";
    } else if (userAgent.match(/safari/i) && !userAgent.match(/chrome|chromium|crios/i)) {
      return "Safari";
    } else if (userAgent.match(/edg/i)) {
      return "Edge";
    } else if (userAgent.match(/firefox|fxios/i)) {
      return "Firefox";
    } else {
      return "Other";
    }
  }

  const browser = detectBrowser();
  if (browser === "Other") {
    alert("Please use Chrome, Safari, FireFox, or Edge to access DVX");
  }

  return (
    <WagmiConfig config={wagmiConfig}>
      <GlobalInit />
      <SnackbarProvider
        maxSnack={3}
        transitionDuration={{ enter: 300, exit: 300 }}
        autoHideDuration={3000}
        action={(key) => <CloseButton snackbarKey={key} />}
        Components={{
          success: SnackbarDesignContentSuccess,
          error: SnackbarDesignContentError,
        }}>
        <div className="App">
          <Router />
        </div>
      </SnackbarProvider>
    </WagmiConfig>
  );
}

export default App;
