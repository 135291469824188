import "./index.scss";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import CryptoJS from "crypto-js";
import { ethers } from "ethers";
import { enqueueSnackbar } from "notistack";
import { useContractWrite, useSignMessage, useWaitForTransaction } from "wagmi";

import AccountAbi from "../../../abis/Account.json";
import { requestMessage } from "../../../apis/message";
import SigningInImage from "../../../assets/pngs/img-sign-in.png";
import { SNACKBAR_MSGS } from "../../../common/constants";
import { MessageType } from "../../../common/enums/messageType";
import { showSnackbar } from "../../../utils/actions";
import InputPassword from "../../Input/InputPassword";
import ModalButton from "../../ModalButton";
import ModalButtonLoading from "../../ModalButton/ModalButtonLoading";
import Modal from "../index";

interface Props {
  onClose: () => void;
  accountAddress: string;
  setIsPasswordRenewed: Dispatch<SetStateAction<boolean>>;
}

const ChangePasswordModal = ({ onClose, accountAddress, setIsPasswordRenewed }: Props) => {
  const { signMessageAsync, error, isError } = useSignMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [virtualAccount, setVirtualAccount] = useState<string>("");
  const [virtualAccountPK, setVirtualAccountPK] = useState<string>("");
  const [passWord, setPassWord] = useState("");
  const [passwordNotEnough, setPasswordNotEnough] = useState(true);

  const handleSignInClick = async () => {
    setIsLoading(true);
    await handleSignIn();
  };

  useEffect(() => {
    if (isError) {
      let message = "";
      if (error?.message.toLowerCase().includes("user rejected")) {
        message = "User rejected the signing.";
      } else {
        message = error?.message || "";
      }
      showSnackbar("error", message);
      handleClose();
    }
  }, [isError]);

  function createVirtualWallet(
    signature: string,
    password: string,
  ): { address: string; privateKey: string } {
    const message = signature + password;
    const hashedInput = CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
    const wallet = new ethers.Wallet(`0x${hashedInput}`);
    return {
      address: wallet.address,
      privateKey: wallet.privateKey,
    };
  }

  const handleSignIn = async () => {
    const createVAMessage = await requestMessage(MessageType.CREATEVA);
    const signedData = await signMessageAsync({
      message: createVAMessage,
    });

    if (signedData !== undefined) {
      const VA = createVirtualWallet(signedData || "", passWord);
      setVirtualAccount(VA.address);
      setVirtualAccountPK(VA.privateKey);
    }
  };

  const { write: renewAccount, data: renewAccountData } = useContractWrite({
    address: (accountAddress as `0x${string}`) || "",
    abi: AccountAbi,
    args: [virtualAccount, "2147483647"],
    functionName: "renewDelegatedAccount",
    onError(error) {
      handleOnError(error.message);
    },
    onSuccess() {
      showSnackbar("success", SNACKBAR_MSGS.SUCCESS_CREATE_ACCOUNT);
    },
  });

  const { isLoading: isRenewAccountLoading } = useWaitForTransaction(renewAccountData?.hash as any);

  useEffect(() => {
    if (virtualAccount !== "" && virtualAccountPK !== "") {
      renewAccount();
    }
    //eslint-disable-next-line
  }, [virtualAccount, virtualAccountPK]);

  const handleOnError = (error: string) => {
    let message: string = "";
    if (error.toLowerCase().includes("user rejected")) {
      message = "User rejected the request.";
    } else if (error.toLowerCase().includes("exceeds allowance")) {
      message = "Transfer amount exceeds allowance.";
    } else {
      message = error;
    }

    showSnackbar("error", message);
    onClose();
  };

  useEffect(() => {
    if (!isRenewAccountLoading && renewAccountData !== undefined) {
      setIsLoading(false);
      setIsPasswordRenewed(true);
      onClose();
    }
  }, [isRenewAccountLoading, renewAccountData]);

  useEffect(() => {
    if (passWord.length === 6) {
      setPasswordNotEnough(false);
    } else {
      setPasswordNotEnough(true);
    }
  }, [passWord]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal title={"Change Password"} onClose={handleClose}>
      <div className={"signing-in-modal-container"}>
        <div className={"signing-in-modal-description"}>
          You'll get a request for signature, which verifies your ownership. There's no gas fee for
          signing. Check your wallet for a signature.
        </div>
        <img className={"signing-in-modal-image"} src={SigningInImage} alt={""} />
        <InputPassword password={passWord} setPassword={setPassWord} />
        {isLoading ? (
          <ModalButtonLoading />
        ) : (
          <ModalButton
            message={`${passwordNotEnough ? "Input 6-digit password" : "Request Sign Message"}`}
            onClick={handleSignInClick}
            disabled={passwordNotEnough}
          />
        )}
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
