import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import notificationMiddleware from "../middleware/notificationMiddleware";

/**
 * 각 역할에 대한 코드 이해 시 순서
 * rootReducer -> slice -> selectors -> useGlobal -> middleware
 */
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(notificationMiddleware),
});

export type AppDispatch = typeof store.dispatch;
