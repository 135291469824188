import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { loadCollateralList } from "../../apis/collateral";
import { loadMarketList } from "../../apis/market";
import { ERRORS } from "../../common/constants";
import { CollateralApiResponse } from "../../types/api/response/AssetResponseTypes";
// import { CurrencyApiResponse } from "../../types/api/response/AssetResponseTypes";
import { MarketApiResponse } from "../../types/api/response/AssetResponseTypes";

interface AssetState {
  // currencies: CurrencyApiResponse[]; // not used / 현재사용 통화, 아직 USD 하나라 사용하지 않음
  isLoadingAsset: boolean;
  collateralList: CollateralApiResponse[]; // 지원 담보 토큰 리스트
  marketList: MarketApiResponse[]; // 지원 마켓(페어) 리스트
  error: string | null;
}

const initialState: AssetState = {
  // currencies: [],
  isLoadingAsset: false,
  collateralList: [],
  marketList: [],
  error: null,
};

export const fetchAssetData = createAsyncThunk(
  "asset/fetchAssetData",
  async (_, { rejectWithValue }) => {
    try {
      const [collaterals, markets] = await Promise.all([loadCollateralList(), loadMarketList()]);

      return { collaterals, markets };
    } catch (error) {
      return rejectWithValue(ERRORS.FETCH_ASSET_ERROR);
    }
  },
);

const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssetData.pending, (state) => {
        state.isLoadingAsset = true;
        state.error = null;
      })
      .addCase(fetchAssetData.fulfilled, (state, action) => {
        state.isLoadingAsset = false;
        state.collateralList = action.payload.collaterals;
        state.marketList = action.payload.markets;
      })
      .addCase(fetchAssetData.rejected, (state, action) => {
        state.isLoadingAsset = false;
        state.error = action.payload as string;
      });
  },
});

const assetReducer = assetSlice.reducer;
export default assetReducer;
