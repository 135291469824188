import { Middleware } from "@reduxjs/toolkit";
import { io, Socket } from "socket.io-client";

import { ERR_MSGS, ERRORS, SNACKBAR_MSGS } from "../../common/constants";
import { NOTIFICATION_CHANNELS } from "../../common/enums/NotificationChannels";
import { WalletInfoType } from "../../types/domain/WalletInfoType";
import { showSnackbar } from "../../utils/actions";
import {
  NotificationAction,
  NotificationResult,
  setLatestNotification,
} from "../slices/notificationSlice";
import { RootState } from "../store/rootReducer";

const notificationMiddleware: Middleware<{}, RootState> = (store) => {
  let socket: Socket | null = null;

  const handleNotification = (htaAddress: string, channel: string, message: string) => {
    const parsedMessage = JSON.parse(message);
    if (htaAddress.toLowerCase() === parsedMessage.account.toLowerCase()) {
      const { action, result } = getNotificationDetails(channel, parsedMessage.status);
      const formattedMessage = formatMessage(channel, result);

      store.dispatch(
        setLatestNotification({
          action,
          result,
          message: formattedMessage,
          data: parsedMessage,
        }),
      );

      showSnackbar(result === NotificationResult.SUCCESS ? "success" : "error", formattedMessage);
    }
  };

  const handleSocketError = (error: any) => {
    console.error(error);
    showSnackbar("error", ERR_MSGS[ERRORS.WEB_SOCKET_ERROR]);
  };

  return (next) => (action: any) => {
    if (action.type === "wallet/setCurrentWallet") {
      if (socket) socket.close();

      const payload = action.payload as WalletInfoType | null;
      const htaAddress = payload?.htaInfo?.address;

      if (
        htaAddress &&
        payload?.htaInfo?.address !== store.getState().wallet.currentWallet?.htaInfo?.address
      ) {
        console.log("Connecting to websocket server");
        socket = io(process.env.REACT_APP_WEBSOCKET_URL || "");
        socket.on("error", handleSocketError);

        Object.values(NOTIFICATION_CHANNELS).forEach((channel) => {
          socket?.on(channel, (message) => handleNotification(htaAddress, channel, message));
        });
      }
    }

    return next(action);
  };
};

function getNotificationDetails(
  channel: string,
  status: string,
): { action: NotificationAction; result: NotificationResult } {
  const actionMap: { [key: string]: NotificationAction } = {
    [NOTIFICATION_CHANNELS.QUERY_DEPOSIT]: NotificationAction.DEPOSIT,
    [NOTIFICATION_CHANNELS.QUERY_WITHDRAW]: NotificationAction.WITHDRAW,
    [NOTIFICATION_CHANNELS.QUERY_SWAP]: NotificationAction.SWAP,
    [NOTIFICATION_CHANNELS.QUERY_COLLATERAL_INCREASE]: NotificationAction.COLLATERAL_INCREASE,
    [NOTIFICATION_CHANNELS.QUERY_COLLATERAL_DECREASE]: NotificationAction.COLLATERAL_DECREASE,
    [NOTIFICATION_CHANNELS.QUERY_LIMIT_ORDER_CREATE]: NotificationAction.LIMIT_ORDER_CREATE,
    [NOTIFICATION_CHANNELS.QUERY_LIMIT_ORDER_CANCEL]: NotificationAction.LIMIT_ORDER_CANCEL,
    [NOTIFICATION_CHANNELS.EVENT_CHECKIN]: NotificationAction.CHECKIN,
  };

  const executeActions = [
    NOTIFICATION_CHANNELS.EXECUTE_INCREASE_GMX,
    NOTIFICATION_CHANNELS.EXECUTE_DECREASE_GMX,
    NOTIFICATION_CHANNELS.EXECUTE_INCREASE_MUX,
    NOTIFICATION_CHANNELS.EXECUTE_DECREASE_MUX,
  ];

  let action = NotificationAction.ERROR;

  for (const [key, value] of Object.entries(actionMap)) {
    if (channel.includes(key)) {
      action = value;
      break;
    }
  }

  if (
    action === NotificationAction.ERROR &&
    executeActions.some((exec) => channel.includes(exec))
  ) {
    action = NotificationAction.ORDER_EXECUTION;
  }

  try {
    if (parseInt(status) === 20) {
      return { action, result: NotificationResult.SUCCESS };
    } else {
      return { action, result: NotificationResult.FAILED };
    }
  } catch (err) {
    return { action, result: NotificationResult.FAILED };
  }
}

function formatMessage(channel: string, result: NotificationResult): string {
  const actionMessages = {
    [NOTIFICATION_CHANNELS.QUERY_DEPOSIT]: "Depositing",
    [NOTIFICATION_CHANNELS.QUERY_WITHDRAW]: "Withdrawing",
    [NOTIFICATION_CHANNELS.QUERY_SWAP]: "Swapping",
    [NOTIFICATION_CHANNELS.QUERY_COLLATERAL_INCREASE]: "Increasing Collateral",
    [NOTIFICATION_CHANNELS.QUERY_COLLATERAL_DECREASE]: "Decreasing Collateral",
    [NOTIFICATION_CHANNELS.QUERY_LIMIT_ORDER_CREATE]: "Creating Limit Order",
    [NOTIFICATION_CHANNELS.QUERY_LIMIT_ORDER_CANCEL]: "Cancelling Limit Order",
    [NOTIFICATION_CHANNELS.EXECUTE_INCREASE_GMX]: "Executing Order in GMX",
    [NOTIFICATION_CHANNELS.EXECUTE_DECREASE_GMX]: "Executing Order in GMX",
    [NOTIFICATION_CHANNELS.EXECUTE_INCREASE_MUX]: "Executing Order in MUX",
    [NOTIFICATION_CHANNELS.EXECUTE_DECREASE_MUX]: "Executing Order in MUX",
    [NOTIFICATION_CHANNELS.EVENT_CHECKIN]: "Checking in",
  };

  let baseMessage = "Something went wrong!";
  for (const [key, message] of Object.entries(actionMessages)) {
    if (channel.includes(key)) {
      baseMessage = message;
      break;
    }
  }

  if (result === NotificationResult.SUCCESS) {
    return `${baseMessage} completed successfully`;
  } else {
    return `${baseMessage} failed`;
  }
}

export default notificationMiddleware;
