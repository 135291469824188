export const DEFAULT_INTERVAL = parseInt(process.env.REACT_APP_DEFAULT_INTERVAL || "5000");
export const DEFAULT_DECIMALS = parseInt(process.env.REACT_APP_DEFAULT_FORMAT_DECIMALS || "2");
export const DEFAULT_LOADING_DURATION = parseInt(
  process.env.REACT_APP_DEFAULT_LOADING_DURATION || "1200",
);
export const DEFAULT_LIMIT_PER_PAGE = 5;
export const DEFAULT_HISTORY_PAGE_LIMIT = parseInt(
  process.env.REACT_APP_DEFAULT_HISTORY_PAGE_LIMIT || "10",
);
export const DEFAULT_WAIT_PAST_ACTION_DURATION = 2000;

export const TOKEN_EXPIRATION_TIME = 1800000; // 30 minutes, 1000 * 60 * 30
// export const TOKEN_EXPIRATION_TIME = 10 * 1000; // 30 minutes, 1000 * 60 * 30
export const TOKEN_EXPIRATION_CHECK_INTERVAL = 1 * 1000; // 5 minutes
export const TYPING_WAIT_TIME = 1000;
export const MAXIMUM_LEVERAGE_MULTIPLIER = 40;

export const ERRORS = {
  // TODO: 지속적으로 단순 텍스트로 정의되어 있는 에러 코드를 const로 정의하여 사용하도록 수정
  FETCH_ASSET_ERROR: "FETCH_ASSET_ERROR",
  LOCAL_LOAD_WALLETS_ERROR: "LOCAL_LOAD_WALLETS_ERROR",
  FETCH_DATA_ERROR: "FETCH_DATA_ERROR",
  SUBMIT_ORDER_ERROR: "SUBMIT_ORDER_ERROR",
  INVALID_WALLET_N_AMOUNT: "INVALID_WALLET_N_AMOUNT",
  WEB_SOCKET_ERROR: "WEB_SOCKET_ERROR",
};

export const ERR_MSGS = {
  // TODO: 지속적으로 단순 텍스트로 정의되어 있는 에러 텍스트를 const로 정의하여 사용하도록 수정
  [ERRORS.FETCH_ASSET_ERROR]: "Failed to fetch asset information. Please try again later.",
  [ERRORS.LOCAL_LOAD_WALLETS_ERROR]:
    "Failed to load wallet information. Please reconnect your wallet.",
  [ERRORS.FETCH_DATA_ERROR]: "Failed to fetch data. Please try again later.",
  [ERRORS.SUBMIT_ORDER_ERROR]: "Failed to place order. Please try again later.",
  [ERRORS.INVALID_WALLET_N_AMOUNT]: "Invalid wallet or amount.",
  [ERRORS.WEB_SOCKET_ERROR]: "Error in websocket connection",
};

export const SNACKBAR_MSGS = {
  // SUCCESS
  SUCCESS_CREATE_ACCOUNT: "Account Created Successfully",
  SUCCESS_CHECK_ATTENDANCE: "Check attendance successfully",
  SUCCESS_DEPOSIT_ETH: "Successfully Deposited ETH",
  SUCCESS_ADD_COLLATERAL: "Successfully added collateral. Will be executed.",
  SUCCESS_SUBMIT_TX: "Transaction submitted successfully",
  SUCCESS_SUBMIT_ORDER_MARKET: "Market order transaction submitted successfully",
  SUCCESS_SUBMIT_ORDER_LIMIT: "Limit order transaction submitted successfully",
  SUCCESS_CREATED_TRIGGER_ORDER: "Created Trigger Order",
  SUCCESS_CANCEL_TRIGGER_ORDER: "Cancelled TP/SL order",
  SUCCESS_CLAIMED_SBT: "SBT Claimed Successfully!",
  // FALIED
  FAILED_SUBMIT_TX: "Transaction submission failed",
  FAILED_SUBMIT_ORDER_MARKET: "Market order transaction submission failed",
  FAILED_SUBMIT_ORDER_LIMIT: "Limit order transaction submission failed",
  FAILED_CANCEL_TRIGGER_ORDER: "Cancel Trigger Order Failed",
  // ETC
  SUCCESS_CLAIMED_PUPPET_BEAR_1: "DVX Puppet Bear 1 NFT Claimed Successfully!",
  SUCCESS_CLAIMED_PUPPET_BEAR_2: "DVX Puppet Bear 2 NFT Claimed Successfully!",
  SUCCESS_CLAIMED_BRONZE_BULL: "DVX Bronze Bull NFT Claimed Successfully!",
  SUCCESS_CLAIMED_SILVER_BULL: "DVX Silver Bull NFT Claimed Successfully!",
  SUCCESS_CLAIMED_GOLD_BULL: "DVX Gold Bull NFT Claimed Successfully!",
  SUCCESS_CLAIMED_BRONZE_BEAR: "DVX Bronze Bear NFT Claimed Successfully!",
  SUCCESS_CLAIMED_SILVER_BEAR: "DVX Silver Bear NFT Claimed Successfully!",
  SUCCESS_CLAIMED_GOLD_BEAR: "DVX Gold Bear NFT Claimed Successfully!",
};
