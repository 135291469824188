import { log } from "console";

import axios from "axios";

import { API_URL } from "./url";
import { UserInfoApiResponse } from "../types/api/response/UserInfoApiResponse";

export const requestCheckUserExist = async (address: string): Promise<boolean> => {
  const res = await axios.get(`${API_URL.USER}/${address}/exist`);
  return res.data.data;
};

export const requestSignUp = async (body: {
  address: string;
  eoa: string;
  refCode: string;
  message: string;
  signature: string;
}): Promise<void> => {
  await axios.post(API_URL.SIGNUP, body);
};

export const requestSignIn = async (body: {
  address: string;
  message: string;
  signature: string;
}): Promise<any> => {
  const res = await axios.post(API_URL.SIGNIN, body);
  return res.data.data;
};

export const requestSignOut = async (body: { address: string }): Promise<void> => {
  const token = localStorage.getItem("token");
  const headers = {
    "X-ACCESS-TOKEN": token,
  };
  await axios.post(API_URL.SIGNOUT, body, { headers });
};

export const requestUserInfo = async (body: {
  address: string;
  message: string;
  signature: string;
}): Promise<UserInfoApiResponse> => {
  const res = await axios.post(`${API_URL.USER}/info`, body);
  return res.data.data;
};

export const requestDelegatedWallet = async (address: string): Promise<string> => {
  const res = await axios.get(`${API_URL.USER}/${address}/delegated_wallet`);
  return res.data.data;
};

export const requestGetAccount = async (address: string): Promise<string> => {
  const res = await axios.get(`${API_URL.USER}/${address}/get_account`);
  return res.data.data;
};
