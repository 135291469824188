import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Notification } from "../../types/api/response/NotificationResponseTypes";

export enum NotificationAction {
  ERROR = "ERROR",
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  SWAP = "SWAP",
  COLLATERAL_INCREASE = "COLLATERAL_INCREASE",
  COLLATERAL_DECREASE = "COLLATERAL_DECREASE",
  LIMIT_ORDER_CREATE = "LIMIT_ORDER_CREATE",
  LIMIT_ORDER_CANCEL = "LIMIT_ORDER_CANCEL",
  ORDER_EXECUTION = "ORDER_EXECUTION",
  CHECKIN = "CHECKIN",
  // Inner data actions
  TRIGGER_ORDER_CREATE = "TRIGGER_ORDER_CREATE",
  TRIGGER_ORDER_CANCEL = "TRIGGER_ORDER_CANCEL",
}

export enum NotificationResult {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

interface NotificationState {
  latestNotification: Notification | null;
}

const initialState: NotificationState = {
  latestNotification: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setLatestNotification: (state, action: PayloadAction<Notification>) => {
      state.latestNotification = action.payload;
    },
    clearLatestNotification: (state) => {
      state.latestNotification = null;
    },
  },
});

export const { setLatestNotification, clearLatestNotification } = notificationSlice.actions;

const notificationReducer = notificationSlice.reducer;
export default notificationReducer;
