import styled from "@emotion/styled";
import { MaterialDesignContent } from "notistack";

export const SnackbarDesignContentSuccess = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#202838",
    border: "1px solid #377BFF",
    borderRadius: "10px",
  },
}));

export const SnackbarDesignContentError = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-error": {
    backgroundColor: "#202838",
    border: "1px solid #F24949;",
    borderRadius: "10px",
  },
}));
