import { combineReducers } from "@reduxjs/toolkit";

import assetReducer from "../slices/assetSlice";
import messageReducer from "../slices/notificationSlice";
import notificationReducer from "../slices/notificationSlice";
import walletReducer from "../slices/walletSlice";

const rootReducer = combineReducers({
  wallet: walletReducer,
  asset: assetReducer,
  message: messageReducer,
  notification: notificationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
