class LocalStorage {
  static setItem(key: string, item: string) {
    if (typeof window !== "undefined") {
      localStorage.setItem(key, item);
    }
  }

  static getItem(key: string) {
    if (typeof window !== "undefined") {
      return localStorage.getItem(key);
    }
    return null;
  }

  static removeItem(key: string) {
    if (typeof window !== "undefined") {
      localStorage.removeItem(key);
    }
  }
}

export default LocalStorage;

export const LOCAL_STORAGE_KEYS = {
  CONNECTED_WALLETS: "connectedWallets",
  CURRENT_WALLET: "currentWallet",
};
