import { createRoutes } from "./CustomRoutes";

export const ROUTES = createRoutes({
  HOME: "/",
  PORTFOLIO: {
    BASE: "/portfolio",
    DVX_ACMM: "/dvx-acmm",
    ASSET: "/asset",
    POSITION: "/position",
    OPEN_ORDER: "/open-order",
    TP_SL_ORDER: "/tp-sl-order",
    HISTORY: "/history",
  },
  REFERRAL: "/referral",
  SHARE: "/share/:id",
  PHASE1: "/beta-mining-phase1",
  PHASE2: "/beta-mining-phase2",
  NOT_YET: "/not-yet",
  TRADE: "/trade",
  NOT_FOUND: "*",
});
