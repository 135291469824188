import { enqueueSnackbar, VariantType } from "notistack";

export const showSnackbar = (
  variant: VariantType = "success",
  message: string,
  align: { ver: string; hor: string } = { ver: "top", hor: "right" },
) => {
  enqueueSnackbar(message, {
    variant,
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  });
};
