import axios, { AxiosResponse } from "axios";

import { API_URL } from "./url";
import { MarketApiResponse } from "../types/api/response/AssetResponseTypes";

export const loadMarketList = async () => {
  const response: AxiosResponse<{ data: MarketApiResponse[] }> = await axios.get(
    API_URL.MARKET_LIST,
  );
  return response.data.data;
};

export const requestNetworkFee = async (action: string) => {
  const res = await axios.get(`${API_URL.NETWORK_FEE}/${action}`);
  return res.data.data;
};

export const requestPrices = async () => {
  const res = await axios.get(API_URL.PRICE);
  return res.data.data;
};
