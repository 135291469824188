import { IconButton } from "@mui/material";
import { closeSnackbar, useSnackbar } from "notistack";

import Close from "../../../assets/svgs/icon-close.svg";

export const CloseButton = ({ snackbarKey }: any) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton size="small" onClick={() => closeSnackbar()}>
      <img className="snackbar-icon-close" src={Close} alt={"close"} />
    </IconButton>
  );
};
