import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ROUTES } from "./ROUTES";
import PageTemplate from "../common/template/PageTemplate";
import PortfolioPageTemplate from "../common/template/PortfolioPageTemplate";
import OffBoardingPage from "../pages/OffBoarding";
import RewardsPage from "../pages/Phase1";
import BetaMiningPhase2Page from "../pages/Phase2";
import PortfolioACMMPage from "../pages/Portfolio/ACMM";
import PortfolioAssetPage from "../pages/Portfolio/Asset";
import PortfolioCloseOrderPage from "../pages/Portfolio/CloseOrder";
import PortfolioHistoryPage from "../pages/Portfolio/History";
import PortfolioOpenOrderPage from "../pages/Portfolio/OpenOrder";
import PortfolioPositionPage from "../pages/Portfolio/Position";
import RedirectPage from "../pages/Redirect";
import ReferralPage from "../pages/Referral";
import SharePage from "../pages/Share";
import TradePage from "../pages/Trade";
import V2ComingSoon from "../pages/V2ComingSoon";

const Router = () => {
  return (
    <BrowserRouter>
      <PageTemplate>
        <Routes>
          <Route path={"*"} element={<V2ComingSoon />} />
          {/* <Route path={ROUTES.HOME} element={<RedirectPage />} />
          <Route path={ROUTES.PORTFOLIO} element={<PortfolioPageTemplate />}>
            <Route path={ROUTES.PORTFOLIO.ASSET} element={<PortfolioAssetPage />} />
            <Route path={ROUTES.PORTFOLIO.DVX_ACMM} element={<PortfolioACMMPage />} />
            <Route path={ROUTES.PORTFOLIO.POSITION} element={<PortfolioPositionPage />} />
            <Route path={ROUTES.PORTFOLIO.OPEN_ORDER} element={<PortfolioOpenOrderPage />} />
            <Route path={ROUTES.PORTFOLIO.TP_SL_ORDER} element={<PortfolioCloseOrderPage />} />
            <Route path={ROUTES.PORTFOLIO.HISTORY} element={<PortfolioHistoryPage />} />
          </Route>
          <Route path={ROUTES.REFERRAL} element={<ReferralPage />} />
          <Route path={ROUTES.SHARE} element={<SharePage />} />
          <Route path={ROUTES.PHASE2} element={<BetaMiningPhase2Page />} />
          <Route path={ROUTES.PHASE1} element={<OffBoardingPage />} />
          <Route path={ROUTES.NOT_YET} element={<OffBoardingPage />} />
          <Route path={ROUTES.NOT_FOUND} element={<TradePage />} /> */}
        </Routes>
      </PageTemplate>
    </BrowserRouter>
  );
};

export default Router;
