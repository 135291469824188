import { useEffect } from "react";

import { useAppDispatch } from "../hooks/useAppDispatch";
import { fetchAssetData } from "../slices/assetSlice";

export const GlobalAssetInit = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAssetData());
  }, [dispatch]);

  return null;
};
