import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ethers } from "ethers";

import { requestMessage } from "../../apis/message";
import { requestDelegatedWallet, requestGetAccount, requestSignIn } from "../../apis/user";
import { TOKEN_EXPIRATION_TIME } from "../../common/constants";
import { MessageType } from "../../common/enums/messageType";
import { EOAInfo, HTAInfo, VAInfo } from "../../types/domain/AccountInfoTypes";
import { WalletInfoType } from "../../types/domain/WalletInfoType";
import { showSnackbar } from "../../utils/actions";
import { LOCAL_STORAGE_KEYS } from "../../utils/localStorage";
import { createVirtualWallet } from "../../utils/walletHelper";

enum WalletErrors {
  PASSWORD_INCORRECT = "Password is incorrect",
}

interface WalletState {
  isLoadingWallet: boolean;
  currentWallet: WalletInfoType | null;
  connectedWallets: WalletInfoType[];
  error: string | null;
}

const initialState: WalletState = {
  isLoadingWallet: false,
  currentWallet: null,
  connectedWallets: [],
  error: null,
};

export const signInWallet = createAsyncThunk(
  "wallet/signIn",
  async (
    { address, signature, password }: { address: string; signature: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      const accountAddress = await requestGetAccount(address);
      const vaAddress = await requestDelegatedWallet(accountAddress);
      const virtualWallet = createVirtualWallet(signature, password);

      if (vaAddress !== virtualWallet.address) {
        return rejectWithValue(WalletErrors.PASSWORD_INCORRECT);
      }

      const createVAMessage = await requestMessage(MessageType.SIGNIN);
      const messageWithTimeStamp = createVAMessage + `\n${Date.now()}`;
      const messageBytes = ethers.toUtf8Bytes(messageWithTimeStamp);
      const vaSignature = await new ethers.Wallet(virtualWallet.privateKey).signMessage(
        messageBytes,
      );

      await requestSignIn({
        address: accountAddress,
        message: messageWithTimeStamp,
        signature: vaSignature,
      });

      const now = new Date().getTime();
      const expirationTime = now + TOKEN_EXPIRATION_TIME;

      const walletInfo: WalletInfoType = {
        eoaInfo: { address } as EOAInfo,
        vaInfo: { address: virtualWallet.address, vaPK: virtualWallet.privateKey } as VAInfo,
        htaInfo: { address: accountAddress } as HTAInfo,
        expirationTime: expirationTime.toString(),
      };

      const storedWallets = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.CONNECTED_WALLETS) || "[]",
      );
      const updatedWallets = [
        ...storedWallets.filter((w: WalletInfoType) => w.eoaInfo?.address !== address),
        walletInfo,
      ];
      localStorage.setItem(LOCAL_STORAGE_KEYS.CONNECTED_WALLETS, JSON.stringify(updatedWallets));
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET, JSON.stringify(walletInfo));

      return walletInfo;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const signOutWallet = createAsyncThunk("wallet/signOut", async () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.CONNECTED_WALLETS);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET);
});

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setCurrentWallet(state, action: PayloadAction<WalletInfoType | null>) {
      state.currentWallet = action.payload;
    },
    updateExpirationTime(state) {
      const now = new Date().getTime();
      const expirationTime = now + TOKEN_EXPIRATION_TIME;

      const walletsString = localStorage.getItem(LOCAL_STORAGE_KEYS.CONNECTED_WALLETS);
      let wallets: WalletInfoType[] = [];

      if (walletsString) {
        wallets = JSON.parse(walletsString);
        const walletIndex = wallets.findIndex(
          (wallet) => wallet.eoaInfo?.address === state.currentWallet?.eoaInfo?.address,
        );

        if (walletIndex >= 0) {
          wallets[walletIndex].expirationTime = expirationTime.toString();
          localStorage.setItem(LOCAL_STORAGE_KEYS.CONNECTED_WALLETS, JSON.stringify(wallets));
        }
      }

      if (state.currentWallet?.eoaInfo?.address && state.currentWallet?.expirationTime) {
        state.currentWallet.expirationTime = expirationTime.toString();
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.CURRENT_WALLET,
          JSON.stringify(state.currentWallet),
        );
      }
    },
    setConnectedWallets(state, action: PayloadAction<WalletInfoType[]>) {
      state.connectedWallets = action.payload;
    },
    addConnectedWallet(state, action: PayloadAction<WalletInfoType>) {
      state.connectedWallets.push(action.payload);
    },
    removeConnectedWallet(state, action: PayloadAction<string>) {
      state.connectedWallets = state.connectedWallets.filter(
        (wallet) => wallet.eoaInfo?.address !== action.payload,
      );
      if (state.currentWallet?.eoaInfo?.address === action.payload) {
        state.currentWallet = null;
      }
    },
    setWalletError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    clearWalletError(state) {
      state.error = null;
    },
    resetWalletState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInWallet.pending, (state) => {
        console.log("Hello1");
        state.isLoadingWallet = true;
        state.error = null;
      })
      .addCase(signInWallet.fulfilled, (state, action) => {
        console.log("Hello2");
        state.isLoadingWallet = false;
        state.currentWallet = action.payload;
        state.connectedWallets.push(action.payload);
        state.error = null;
      })
      .addCase(signInWallet.rejected, (state, action) => {
        state.isLoadingWallet = false;
        state.error = action.payload as string;
        showSnackbar("error", state.error);
      })
      .addCase(signOutWallet.pending, (state) => {
        state.isLoadingWallet = true;
        state.error = null;
      })
      .addCase(signOutWallet.fulfilled, (state) => {
        state.isLoadingWallet = false;
        state.currentWallet = null;
        state.connectedWallets = [];
        state.error = null;
      })
      .addCase(signOutWallet.rejected, (state, action) => {
        state.isLoadingWallet = false;
        state.error = action.payload as string;
        showSnackbar("error", state.error);
      });
  },
});

export const {
  setCurrentWallet,
  updateExpirationTime,
  setConnectedWallets,
  addConnectedWallet,
  removeConnectedWallet,
  setWalletError,
  clearWalletError,
  resetWalletState,
} = walletSlice.actions;

const walletReducer = walletSlice.reducer;
export default walletReducer;
