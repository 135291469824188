import { useCallback, useEffect } from "react";

import { useAccount, useDisconnect } from "wagmi";

import { ERR_MSGS, ERRORS, TOKEN_EXPIRATION_CHECK_INTERVAL } from "../../common/constants";
import { WalletInfoType } from "../../types/domain/WalletInfoType";
import { showSnackbar } from "../../utils/actions";
import { LOCAL_STORAGE_KEYS } from "../../utils/localStorage";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useGlobalWallet } from "../hooks/useGlobalWallet";
import {
  setConnectedWallets,
  setCurrentWallet,
  signOutWallet,
  updateExpirationTime,
} from "../slices/walletSlice";

export const GlobalWalletInit = () => {
  const dispatch = useAppDispatch();
  const { currentWallet } = useGlobalWallet();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const clearOldData = useCallback(() => {
    if (!localStorage.getItem("loggedIn") || !localStorage.getItem("currentLoginInfo")) return;

    localStorage.removeItem("loggedIn");
    localStorage.removeItem("currentLoginInfo");
    dispatch(setConnectedWallets([]));
    dispatch(setCurrentWallet(null));
    disconnect();
  }, [dispatch, disconnect]);

  const loadWalletsFromStorage = useCallback(() => {
    try {
      const newWalletsData = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.CONNECTED_WALLETS) || "[]",
      ) as WalletInfoType[];
      const newCurrentWalletData = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET) || "{}",
      ) as WalletInfoType;

      return {
        connectedWallets: newWalletsData,
        currentWallet: newCurrentWalletData,
      };
    } catch (error) {
      console.error(`${ERRORS.LOCAL_LOAD_WALLETS_ERROR}: `, error);
      showSnackbar("error", ERR_MSGS[ERRORS.LOCAL_LOAD_WALLETS_ERROR]);
      return {
        connectedWallets: [],
        currentWallet: null,
      };
    }
  }, []);

  const setTokenExpirationChecker = useCallback(() => {
    if (!currentWallet?.htaInfo?.address) return () => {};

    const intervalId = setInterval(() => {
      const newWalletInfo = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET) || "{}",
      ) as WalletInfoType;

      if (address && newWalletInfo.htaInfo?.address && newWalletInfo.expirationTime) {
        const timeDiff = parseInt(newWalletInfo.expirationTime) - Date.now();
        const secondsRemaining = Math.floor(timeDiff / 1000);

        if (secondsRemaining < 1) {
          dispatch(signOutWallet());
          disconnect();
          clearInterval(intervalId);
        }
      } else {
        clearInterval(intervalId);
      }
    }, TOKEN_EXPIRATION_CHECK_INTERVAL);

    return () => clearInterval(intervalId);
  }, [currentWallet?.htaInfo?.address]);

  const setExtendExpirationTimeListener = useCallback(() => {
    const currentWalletLocal = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET) || "{}",
    ) as WalletInfoType;

    const extendExpirationTime = () => {
      if (currentWalletLocal.expirationTime) {
        dispatch(updateExpirationTime());
      }
    };

    const shouldAddListeners = currentWalletLocal.htaInfo?.address;
    const events = ["mousemove", "keydown"];

    events.forEach((event) => {
      document.removeEventListener(event, extendExpirationTime);
      if (shouldAddListeners) {
        document.addEventListener(event, extendExpirationTime);
      }
    });

    return () => {
      events.forEach((event) => document.removeEventListener(event, extendExpirationTime));
    };
  }, [dispatch]);

  const initializeWallets = useCallback(() => {
    console.log("initializeWallets");
    clearOldData(); // 예전 버젼 Local 데이터 모두 제거

    const { currentWallet: newLocalWallet, connectedWallets: newLocalWallets } =
      loadWalletsFromStorage();

    if (isConnected && address) {
      if (newLocalWallet) {
        dispatch(setCurrentWallet(newLocalWallet));
        dispatch(setConnectedWallets(newLocalWallets));
      } else {
        disconnect();
      }
    }
  }, [clearOldData, dispatch, isConnected, address, disconnect, loadWalletsFromStorage]);

  useEffect(() => {
    initializeWallets();
  }, []);

  // Effect for setting up wallet-related background actions
  useEffect(() => {
    const cleanupExpirationChecker = setTokenExpirationChecker();
    const cleanupExpirationListener = setExtendExpirationTimeListener();

    return () => {
      cleanupExpirationChecker();
      cleanupExpirationListener();
    };
  }, [setTokenExpirationChecker, setExtendExpirationTimeListener]);

  return null;
};

// 추후 추가 기능 :: 계정 여러개 연결 시 사용
// const saveWalletsToStorage = useCallback((wallets: WalletInfo[]) => {
//   try {
//     localStorage.setItem("connectedWallets", JSON.stringify(wallets));
//   } catch (error) {
//     console.error("Failed to save wallets to storage:", error);
//     enqueueSnackbar("Failed to save wallet information.", { variant: "error" });
//   }
// }, []);

// useEffect(() => {
//   if (!isConnected && connectedWallets.length > 0) {
//     const updatedWallets = connectedWallets.filter(
//       (w) => w.eoaInfo?.address.toLowerCase() !== address?.toLowerCase(),
//     );
//     dispatch(setConnectedWallets(updatedWallets));
//     saveWalletsToStorage(updatedWallets);
//   }
// }, [isConnected, address, connectedWallets, dispatch, saveWalletsToStorage]);
