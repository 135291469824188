import axios, { AxiosResponse } from "axios";

import { API_URL } from "./url";
import { CollateralApiResponse } from "../types/api/response/AssetResponseTypes";

export const loadCollateralList = async () => {
  const response: AxiosResponse<{ data: CollateralApiResponse[] }> = await axios.get(
    API_URL.COLLATERAL_LIST,
  );
  return response.data.data;
};
