import { GlobalAssetInit } from "./GlobalAssetInit";
import { GlobalNotificationInit } from "./GlobalNotification";
import { GlobalWalletInit } from "./GlobalWalletInit";

export const GlobalInit = () => {
  return (
    <>
      <GlobalAssetInit />
      <GlobalWalletInit />
      <GlobalNotificationInit />
    </>
  );
};
