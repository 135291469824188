export const NOTIFICATION_CHANNELS = {
  QUERY_DEPOSIT: "QUERY:DEPOSIT",
  QUERY_WITHDRAW: "QUERY:WITHDRAW",
  QUERY_SWAP: "QUERY:SWAP",
  QUERY_COLLATERAL_INCREASE: "QUERY:COLLATERAL:INCREASE",
  QUERY_COLLATERAL_DECREASE: "QUERY:COLLATERAL:DECREASE",
  QUERY_LIMIT_ORDER_CREATE: "QUERY:LIMIT:CREATE",
  QUERY_LIMIT_ORDER_CANCEL: "QUERY:LIMIT:CANCEL",
  EVENT_CHECKIN: "EVENT:CHECKIN",
  EXECUTE_INCREASE_GMX: "EXECUTE:INCREASE:GMX",
  EXECUTE_DECREASE_GMX: "EXECUTE:DECREASE:GMX",
  EXECUTE_INCREASE_MUX: "EXECUTE:INCREASE:MUX",
  EXECUTE_DECREASE_MUX: "EXECUTE:DECREASE:MUX",
} as const;

export type NotificationChannel = keyof typeof NOTIFICATION_CHANNELS;
