import "./index.scss";
import { useEffect, useState } from "react";

import { enqueueSnackbar } from "notistack";
import { useAccount, useDisconnect, useNetwork, useSignMessage, useSwitchNetwork } from "wagmi";
import { arbitrum } from "wagmi/chains";

import { requestMessage } from "../../../apis/message";
import { requestGetAccount } from "../../../apis/user";
import SigningInImage from "../../../assets/pngs/img-sign-in.png";
import { MessageType } from "../../../common/enums/messageType";
import { useAppDispatch } from "../../../redux/hooks/useAppDispatch";
import { useAppSelector } from "../../../redux/hooks/useAppSelector";
import { useGlobalWallet } from "../../../redux/hooks/useGlobalWallet";
import { setWalletError, signInWallet } from "../../../redux/slices/walletSlice";
import { selectWalletState } from "../../../redux/store/selectors";
import { showSnackbar } from "../../../utils/actions";
import InputPassword from "../../Input/InputPassword";
import ModalButton from "../../ModalButton";
import ModalButtonLoading from "../../ModalButton/ModalButtonLoading";
import ChangePasswordModal from "../ChangePasswordModal";
import Modal from "../index";

interface Props {
  onClose: () => void;
  isExtension: boolean;
}

const SigningInModal = ({ onClose, isExtension }: Props) => {
  const dispatch = useAppDispatch();
  const { error: walletError } = useGlobalWallet();
  const { address } = useAccount();

  const wallet = useAccount();
  const { disconnect } = useDisconnect();
  const { chains, chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { signMessageAsync, error, isError } = useSignMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordNotEnough, setPasswordNotEnough] = useState(true);
  const [htaAddress, setHTAAddress] = useState<string>("");
  const [isRenewPassword, setIsRenewPassword] = useState<boolean>(false);
  const [isPasswordRenewed, setIsPasswordRenewed] = useState<boolean>(false);

  const handleSignIn = async () => {
    try {
      const isSupportedNetwork = !!chains.find((supportedChain) => supportedChain.id === chain?.id);

      if (!isSupportedNetwork) {
        await switchNetworkAsync?.(arbitrum.id);
      }

      setIsLoading(true);

      const createVAMessage = await requestMessage(MessageType.CREATEVA);
      const signedData = await signMessageAsync({
        message: createVAMessage,
      });

      if (signedData !== undefined && !passwordNotEnough) {
        await dispatch(
          signInWallet({
            address: wallet.address || "",
            signature: signedData,
            password: passwordInput,
          }),
        ).unwrap();
      }

      setIsLoading(false);
      onClose();
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (!isExtension) {
      disconnect();
    }
    onClose();
  };

  const handlePasswordEnter = () => {
    if (!passwordNotEnough) {
      handleSignIn();
    }
  };

  useEffect(() => {
    if (isError) {
      let message = "";
      if (error?.message.toLowerCase().includes("user rejected")) {
        message = "User rejected the signing.";
      } else {
        message = error?.message || "";
      }
      showSnackbar("error", message);
      handleClose();
    }
  }, [isError]);

  useEffect(() => {
    if (passwordInput.length === 6) {
      setPasswordNotEnough(false);
    } else {
      setPasswordNotEnough(true);
    }
  }, [passwordInput]);

  useEffect(() => {
    if (isPasswordRenewed) {
      dispatch(setWalletError(null));
      setPasswordInput("");
    }
  }, [isPasswordRenewed]);

  useEffect(() => {
    const getHTAAddress = async () => {
      const htaAddressData = await requestGetAccount(address?.toLowerCase() || "");
      setHTAAddress(htaAddressData);
    };

    if (address) {
      getHTAAddress();
    }
  }, [address]);

  return (
    <>
      <Modal title={"Signing In"} onClose={handleClose}>
        <div className={"signing-in-modal-container"}>
          <div className={"signing-in-modal-description"}>
            You'll get a request for signature, which verifies your ownership. There's no gas fee
            for signing. Check your wallet for a signature.
          </div>
          <img className={"signing-in-modal-image"} src={SigningInImage} alt={""} />
          <InputPassword
            password={passwordInput}
            setPassword={setPasswordInput}
            isPasswordRenewed={isPasswordRenewed}
            onEnter={handlePasswordEnter}
          />
          {isLoading ? (
            <ModalButtonLoading />
          ) : (
            <ModalButton
              message={`${passwordNotEnough ? "Input 6-digit password" : "Request Sign Message"}`}
              onClick={handleSignIn}
              disabled={passwordNotEnough}
            />
          )}
          {walletError && (
            <div className={"va-error-message-box"}>
              <div className={"va-error-message"}> {walletError} </div>
              <div className={"va-reset"} onClick={() => setIsRenewPassword(true)}>
                {" "}
                Change Password
              </div>
            </div>
          )}
        </div>
      </Modal>
      {isRenewPassword && (
        <ChangePasswordModal
          onClose={() => setIsRenewPassword(false)}
          accountAddress={htaAddress}
          setIsPasswordRenewed={() => setIsRenewPassword(false)}
        />
      )}
    </>
  );
};

export default SigningInModal;
