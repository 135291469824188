import CryptoJS from "crypto-js";
import { ethers } from "ethers";

export function createVirtualWallet(
  signature: string,
  password: string,
): { address: string; privateKey: string } {
  const message = signature + password;
  const hashedInput = CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
  const wallet = new ethers.Wallet(`0x${hashedInput}`);
  return {
    address: wallet.address,
    privateKey: wallet.privateKey,
  };
}
