import "./index.scss";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/svgs/icon-logo.svg";
import { ROUTES } from "../../routes/ROUTES";
import HeaderAccountInfo from "../HeaderAccountInfo";
import WalletConnect from "../WalletConnect";

const Header = () => {
  const navigate = useNavigate();
  const location = window.location.href;

  return (
    <div className={"header-container"}>
      <div className={"header-box-left"}>
        <img className={"logo"} src={Logo} alt={""} onClick={() => navigate(ROUTES.TRADE)} />
      </div>
    </div>
  );
};

export default Header;
