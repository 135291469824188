import { useRef, useEffect, Dispatch, SetStateAction } from "react";
import "./index.scss";

interface Props {
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  isPasswordRenewed?: boolean;
  disabled?: boolean;
  onEnter?: () => void;
}
const InputPassword = ({ password, setPassword, disabled, isPasswordRenewed, onEnter }: Props) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(value) && value.length <= 6) {
      setPassword(value);
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onEnter) {
      onEnter();
    }
  };

  useEffect(() => {
    if (isPasswordRenewed) {
      setPassword("");
    }
    //eslint-disable-next-line
  }, [isPasswordRenewed]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  return (
    <div className={"input-password-container"}>
      <input
        ref={(ref) => (inputRefs.current[0] = ref)}
        className={"input-password-input-space"}
        value={password}
        onChange={handleChange}
        maxLength={6}
        type={"password"}
        placeholder={"●●●●●●"}
        onKeyDown={handleEnter}
        disabled={disabled}
      />
    </div>
  );
};

export default InputPassword;
