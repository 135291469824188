import "./index.scss";
import { useState } from "react";

import Header from "../../../components/Header";
import SigningInModal from "../../../components/Modal/SigningInModal";
interface Props {
  children?: any;
}

interface session {
  wallet: string;
  virtualAccountAddress: string;
  virtualAccountPK: string;
  expirationTime: string;
  accountAddress: string;
}

const PageTemplate = ({ children }: Props) => {
  const [openSigningInModal, setOpenSigningInModal] = useState<boolean>(false);

  return (
    <div className={"page-template"}>
      <Header />
      <div className={"content"}>{children}</div>
      {openSigningInModal && (
        <SigningInModal
          isExtension={true}
          onClose={() => {
            setOpenSigningInModal(false);
          }}
        />
      )}
    </div>
  );
};

export default PageTemplate;
