export enum MessageType {
  CREATEVA = "createva",
  SIGNUP = "signup",
  SIGNIN = "signin",
  GET_USER_INFO = "get_user_info",
  CREATE_REFERRAL_CODE = "create_referral_code",
  GET_REFERRAL_CODE = "get_referral_code",
  GET_REFERRAL_HISTORY = "get_referral_history",
  GET_DEPOSIT_HISTORY = "get_deposit_history",
  GET_WITHDRAWAL_HISTORY = "get_withdrawal_history",
  GET_SWAP_HISTORY = "get_swap_history",
  GET_ACTIVE_LIMIT_ORDER = "get_active_limit_order",
  GET_LIMIT_ORDER_HISTORY = "get_limit_order_history",
  GET_ACTIVE_TRIGGER_ORDER = "get_active_trigger_order",
  GET_TRIGGER_ORDER_HISTORY = "get_trigger_order_history",
  GET_MARKET_ORDER_HISTORY = "get_market_order_history",
  GET_COLLATERAL_MANAGEMENT_HISTORY = "get_collateral_management_history",
  GET_ACMM_MARGIN_HISTORY = "get_acmm_margin_history",
  GET_DEPOSIT_TRANSACTION = "get_deposit_transaction",
  GET_WITHDRAWAL_TRANSACTION = "get_withdrawal_transaction",
  GET_SWAP_TRANSACTION = "get_swap_transaction",
  QUERY_MARKET_OPEN_TRANSACTION = "query_market_open_transaction",
  QUERY_MARKET_CLOSE_TRANSACTION = "query_market_close_transaction",
  QUERY_INCREASE_COLLATERAL_TRANSACTION = "query_increase_collateral_transaction",
  QUERY_DECREASE_COLLATERAL_TRANSACTION = "query_decrease_collateral_transaction",
  QUERY_LIMIT_ORDER_TRANSACTION = "query_limit_order_transaction",
  QUERY_CANCEL_LIMIT_ORDER_TRANSACTION = "query_cancel_limit_order_transaction",
  QUERY_EVENT_CHECKIN_TRANSACTION = "query_event_checkin_transaction",
  GET_ACCUMULATED_REBATE = "get_accumulated_rebate",
  GET_ACCUMULATED_VOLUME = "get_accumulated_volume",
  CANCEL_TRIGGER_ORDER = "cancel_trigger_order",
  GET_PHASE1_DATA = "get_phase1_data",
  GET_ACCOUNT_BALANCE = "get_account_balance",
  GET_WALLET_BALANCE = "get_wallet_balanc",
  GET_ACCOUNT_WITHDRAWABLE_BALANCE = "get_account_withdrawable_balance",
  GET_ACCOUNT_FEE_DEBT = "get_account_fee_debt",
  GET_TOKEN_AMOUNT_IN_USE = "get_token_amount_in_use",
  GET_POSITIONS = "get_positions",
  GET_ESTIMATE_LIQUIDATION_PRICE = "get_estimate_liquidation_price",
  GET_LIQUIDATION_PRICE = "get_liquidation_price",
  GET_POSITION_NET_VALUE_TOKEN = "get_position_net_value_token",
  GET_LIMIT_ORDERS = "get_limit_orders",
}
